$(document).on('turbolinks:before-cache', function () {
  $('.select2').select2('destroy');
  $('.modal .select2').select2('destroy');
});

$(document).on('ready turbolinks:load', function() {

  const canvas       = document.querySelector("canvas");

  if (canvas != null) {
    const signaturePad = new SignaturePad(canvas);

    $('#signature-pad').on('click', function(event){
      event.preventDefault();

      if (signaturePad.isEmpty()) {
        return alert("Please provide a signature first.");
      }
    
      var data = signaturePad.toDataURL('image/svg+xml');
      
      $('#signature').val(data)
    });

    $('.signature-clear').on('click', function(event){
      event.preventDefault();
    
      signaturePad.clear();
      $('#signature').val(null);
    });
  }

  function copyClipboard(str) {

    const el = document.createElement('textarea');
    el.value = str;

    navigator.clipboard.writeText(str);

    console.log("Copied to Clipboard: " + str)

  };

  $(".inputInsert-trigger").on("keyup", function(event){
    var target = $(this).data('input-insert-target'),
        placeholder = $(this).data('input-insert-placeholder'),
        new_value = $(this).val();

    if (new_value == '') {
      $(target).html(placeholder);
    } else {
      $(target).html(new_value);
    }
  }).keyup();

  $( ".select2" ).select2({
    theme: 'bootstrap-5'
  });

  $('.trigger-copy').click(function(event){
    event.preventDefault()

    var parent      = $(this).closest('.copy-wrapper'),
        input_value = $('input', parent).val(),
        button      = $('.btn', parent),
        initial_html = button.html();

    copyClipboard(input_value)

    button.html('Copied to Clipboard!');

    setTimeout(function() {
      button.html(initial_html);
    }, 3000);
  })

  $('.revealOther input').change(function(){
    var target = $(this).data('other-target'),
        value  = $(this).val();
    
    if ($(this).is(':checked') && value == 'Other') {
      $(target).slideDown();
    } else if ($(this).is(':checked') && value == '1') {
      $(target).slideDown();
    } else {
      console.log(value)
      $(target).slideUp();
    }
  }).change();

  $('.url-review').change(function(){
    str = $(this).val();
    validation_check = str.includes("http") != true || str.includes(".") != true

    if (validation_check && str.length > 8) {
      $(this).val(str);
      $('.hint', $(this).parent('div')).html('Attention: We noticed an incomplete url, so we made a modification. Does the above url look correct?');
    }
  });

  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
  const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

  if ($('.tinymce').length > 0) {
    tinymce.init({
      selector: '.tinymce',
      height: 500,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar: 'undo redo | formatselect | ' +
      ' bold italic backcolor | alignleft aligncenter ' +
      ' alignright alignjustify | bullist numlist outdent indent | ' +
      ' removeformat | help'
    });
  };

  $('#user_estimated_monthly_net_cashflow').addClass('field-required');
  
  $('.field-required').change(function(){
    var form             = $(this).closest('form'),
        button           = $('input[type="submit"]', form),
        incomplete_count = 0;

    $('.field-required', form).each(function(i, obj) {
      var empty       = ($(this).val() == ''),
          is_checkbox = $(this).hasClass('boolean'),
          is_checked  = $(this).is(':checked');

      if (empty || (is_checkbox && is_checked == false)) {
        incomplete_count += 1;
      }
    });

    if (incomplete_count == 0) {
      $('input[type="submit"]', form).removeAttr('disabled');
    } else {
      button.attr('disabled', 'disabled');
    }


  }).change();

  $("input.currency-input").keyup(function(event){
    // skip for arrow keys
    if(event.which >= 37 && event.which <= 40){
      event.preventDefault();
    }

    var $this = $(this);
    var num   = $this.val().split(".")[0].toString().replace(/[^0-9]/g, '');
    var num2  = num.replace(/,/gi, "");
  	var num3  = num2.split(/(?=(?:\d{3})+$)/).join(",");
    
    // the following line has been simplified. Revision history contains original.
    $this.val(num3);
  }).keyup();

  $('.toggle-trigger').click(function(event){
    event.preventDefault();
    target = $(this).data('toggleable');

    if ($(target).hasClass('d-none')) {
      $(target).removeClass('d-none');
    } else {
      $(target).toggle();
    }
  });

  $(".alerts").delay(5000).slideUp(500);

  $('form').one('submit', function(e) {
    event.preventDefault();

    $('.currency-input').each(function() {
      var clean = $(this).val().split(",").join("")

      $(this).val(clean);
    });

    $(this).submit();
  });

  $('form').on('click', '.remove_fields', function(event) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('fieldset').hide();
    return event.preventDefault();
  });

  $('form').on('click', '.add_fields', function(event) {
    var regexp, time;
    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $(this).before($(this).data('fields').replace(regexp, time));
    return event.preventDefault();
  });
});
